import React from 'react';
import TitleCard from './TitleCard';
import SecureDataCard from './SecureDataCard';
import DiscoverCard from './DiscoverCard';
import {CSS} from '../../utils/domUtils';
import CollaborationCard from './CollaborationCard';
import Partners from './Partners';
import '../../styles/_homePage.scss';
import HowWeProtect from '../common/HowWeProtect';

const HomepageRoute: React.FC = () => (
  <div className={CSS.HOME_PAGE_ROUTE}>
    <TitleCard/>
    <SecureDataCard/>
    <HowWeProtect/>
    <DiscoverCard/>
    <CollaborationCard/>
    <Partners/>
  </div>
);

export default HomepageRoute;
