import {CSS} from '../../utils/domUtils';

type DemoButtonProps = {
	buttonText: string;
    extraClasses?: string;
};

const DemoButton = ({buttonText, extraClasses}: DemoButtonProps) => (
    <a href={'https://platform.demo.xtendr.io/'}  target="_blank" rel="noreferrer">
        <button className={`${CSS.BUTTON_GREEN} ${extraClasses}`}><div>{buttonText}</div></button>
    </a>
);

export default DemoButton;
