export enum CSS {
    ACCORDION='accordion',
	ACCORDION_IMG='accordion-img',
    ACCORDION_WRAPPER='accordion-wrapper',
    ACTIVE='active',
    ARROW_ICON='arrow-icon',
    ARROW_UP='arrow-up',
    ARROW_DOWN='arrow-down',
    BACKGROUND='background',
    BUTTON_DARK='button-dark',
    BUTTON_GREEN='button-green',
	CARD_FLEX='card-flex',
    CARD_TITLE='card-title',
    CARD_TEXT='card-text',
    CARD_SUBTITLE='card-subtitle',
    CLOSED_MENU='closed-menu',
	COLLAB_FLEX_ROW='collab-flex-row',
    COLLAB_SMALL_IMG='collab-small-img',
    COLLAB_WRAPPER='collab-wrapper',
    COLLABORATION_CARD='collaboration-card',
    COLLABORATION_TEXT='collaboration-text',
    COLLABORATION_PLATFORM='collaboration-platform',
    COLLABORATION_PROJECT_IMG='collaboration-project-img',
    COMBINING_CARD='combining-card',
    COMING_SOON='coming-soon',
    COMING_SOON_ROUTE='coming-soon-route',
	COMMITED='commited',
    CONSULTATION='consultation',
    CONSULT_WRAPPER='consult-wrapper',
    CONTAINER='container',
    CONTACT='contact',
    CONTACT_BUTTON='contact-button',
    CONTACT_LINK='contact-link',
    DARK_CARD='dark-card',
	DEMO_ROUTE='demo-route',
	DEMO_CARD='demo-card',
    CUSTOMIZIZATION_CARD='customization-card',
    DISCOVER_CARD='discover-card',
    DISCOVER_CARD_LIST='discover-card-list',
    LIST_ITEM_WITH_ARROW='list-item-with-arrow',
    DISPLAY_BIGSCREEN='display-bigscreen',
    DISPLAY_GIANTSCREEN='display-giantscreen',
    DISPLAY_MIDDLESCREEN='display-middlescreen',
    DISPLAY_SMALLSCREEN='display-smallscreen',
    EMAIL_INPUT='email-input',
	FEATURES_CARD='features-card',
	FLEX='flex',
	FLEX_CENTER='flex-center',
    FLEX_ROW='flex-row',
    FLEX_COLUMN='flex-column',
    FOOTER='footer',
    FOOTER_INFO='footer-info',
    FOOTER_LOGO='footer-logo',
    FOOTER_LEGAL='footer-legal',
    GAP='gap',
    FULL_HEIGHT='full-height',
    HEADER='header',
    HEADER_FOOTER='header-footer',
    HOME_PAGE_ROUTE='home-page-route',
    HOW_IT_WORKS_CARD='how-it-works-card',
	HOW_IT_WORKS_IMG='how-it-works-img',
	HOW_IT_WORKS_IMG_SMALL='how-it-works-img-small',
	HOW_IT_WORKS_IMG_WRAPPER='how-it-works-img-wrapper',
    HOW_IT_WORKS_WRAPPER='how-it-works-wrapper',
    HOVER='hover',
    IMG='img',
    ILLUSTRATION_INNER_WRAPPER='illustration-inner-wrapper',
    ILLUSTRATIONS_WRAPPER='illustrations-wrapper',
    LIGHT='light',
    MENU='menu',
    MENU_BUTTON='menu-button',
    MENU_ITEM='menu-item',
    MENU_ITEM_ACTIVE='menu-item-active',
    MENU_OPEN='menu-open',
    MENU_WRAPPER='menu-wrapper',
    LAST='last',
    LAPTOP_IMG='laptop-img',
    LEFT_MARGIN='left-margin',
    LEFT_MIDDLE_TEXT='left-middle-text',
    LEFT_SUBTITLE='left-subtitle',
    LIGHT_BACKGROUND='light-background',
    LINKEDIN_BTN='linkedin-btn',
    LINKEDIN_BIGSCREEN='linkedin-bigscreen',
    LINKEDIN_SMALLSCREEN='linkedin-smallscreen',
    LINKS='links',
    LINKS_SMALLSCREEN='links-smallscreen',
    LINK_COLUMN='link-column',
	LIST='list',
    LOGO='logo',
    LOGO_DARK='logo-dark',
    LOGO_SCROLLER='logo-scroller',
    NEWSLETTER='newsletter',
    MAIN_TEXT='main-text',
    IMG_WRAPPER='img-wrapper',
    OPEN_MENU='open-menu',
    OUR_PLATFORM_BUTTON='our-platform-button',
	OUTCOMES_CARD='outcomes-card',
	OUTCOMES_CARD_TITLE='outcomes-card-title',
	OUTCOMES_BASE='outcomes-base',
	OUTOMES_IMG='outcomes-img',
    PADDING='padding',
    PANEL='panel',
    PARTNERS_TITLE='partners-title',
    PARTNERS='partners',
	PLANNING='planning',
	PLANNING_TITLE='planning-title',
	PLANNING_SUBTITLE='planning-subtitle',
    PRIVACY_CARD_HORIZONTAL='privacy-card-horizontal',
    PRIVACY_CARD_VERTICAL='privacy-card-vertical',
    PRIVACY_TEXT='privacy-text',
    PRIVACY_SUBTEXT='privacy-subtext',
    PROTECT_DATA='protect-data',
    RIGHT_SUBTITLE='right-subtitle',
    SECURE_DATA_TITLE='secure-data-title',
    SECURE_DATA_CARD='secure-data-card',
    SECURE_DATA_IMG='secure-data-img',
    SECURE_DATA_IMG_MOBILE='secure-data-img-mobile',
    SECURE_DATA_FIRST_ROW='secure-data-first-row',
    SIDE_MENU='side-menu',
    SMALL_CARD='small-card',
    SUBMENU_WRAPPER='submenu-wrapper',
    SUB_TEXT='sub-text',
	TEXT_LINK='text-link',
    TEXT='text',
    TITLE_CARD='title-card',
    TITLE_CARD_TITLE='title-card-title',
	TITLE_WRAPPER='title-wrapper',
    TRY_DEMO='try-demo',
    TRY_IT_NOW='try-it-now',
    WRAPPER='wrapper',
}
