import React from 'react';
import TitleCard from './TitleCard';
import Rest from './Rest';
import {CSS} from '../../utils/domUtils';

const DemoRoute: React.FC = () => (
    <div className={CSS.DEMO_ROUTE}>
        <TitleCard/>
        <Rest/>
    </div>
);

export default DemoRoute;