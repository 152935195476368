import React from 'react';
import {CSS} from '../../utils/domUtils';
import laptopIllustration from '../../resources/img/screenBig.svg';
import laptopMobileIllustration from '../../resources/img/smallScreen.png';
import laptopGiantIllustration from '../../resources/img/screenGiant.svg';
import { Link } from 'react-router-dom';
import CollaborationCustomizable from './CollaborationCustomizable';
import { PATHS } from '../../utils/paths';
import '../../styles/homePage/_collaborationCard.scss';
import '../../styles/homePage/_consultation.scss';

const CollaborationCard = () => (
    <div className={`${CSS.COLLABORATION_CARD} ${CSS.BACKGROUND}`}>
        <div className={CSS.LEFT_MARGIN}>
        <div className={`${CSS.CONTAINER} ${CSS.COLLAB_WRAPPER}`}>
            <div className={CSS.FLEX_ROW}>
                <div className={CSS.COLLABORATION_TEXT}>
                    <div className={CSS.CARD_TITLE}>The xtendr Collaboration Platform</div>
                    <div className={CSS.CARD_SUBTITLE}>
                        Designed for simple, streamlined & secure collaboration projects.
                    </div>
                    <div className={CSS.CARD_SUBTITLE}>
                        Featuring <b>pre-set queries</b> and an <b>optional custom SQL query builder</b>.
                    </div>
                    <div className={CSS.IMG_WRAPPER}><img className={CSS.DISPLAY_SMALLSCREEN} src={laptopMobileIllustration} alt="laptop illustration" /></div>
                    <div className={CSS.CARD_SUBTITLE}>
                        Easy-to-use adjustable access controls and an accessible interface that <b>doesn’t require programming knowledge to use.</b>
                    </div>
                    <div className={CSS.CARD_SUBTITLE}>
                        Different security configurations available, implemented <b>according to your needs</b> during a fully supported setup phase.
                    </div>
                    <div className={CSS.TEXT_LINK}><Link to={PATHS.COLLABORATION_PLATFORM}>HOW IT WORKS&nbsp;&nbsp; →</Link></div>
                </div>
                <div className={CSS.LAPTOP_IMG}>
                    <img className={CSS.DISPLAY_BIGSCREEN} src={laptopIllustration} alt="laptop illustration" />
                    <img className={CSS.DISPLAY_GIANTSCREEN} src={laptopGiantIllustration} alt="laptop illustration" />
                </div>
            </div>
        </div>
        </div>
        <CollaborationCustomizable/>
        <div className={CSS.LEFT_MARGIN}>
            <div className={`${CSS.CONTAINER} ${CSS.CONSULT_WRAPPER}`}>
                <div className={CSS.CONSULTATION}>
                    <div className={CSS.CARD_TITLE}>consultation</div>
                    <div className={CSS.CARD_SUBTITLE}>
                        Every xtendr project begins with a full consultation <u>to help us understand your collaboration needs
                        and challenges</u>, including advice on how collaboration can best work for you.
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default CollaborationCard;
