import React from 'react';
import {Link} from 'react-router-dom';
import {CSS} from '../../utils/domUtils';

type ButtonDarkProps = {
	buttonText: string;
    extraClasses?: string;
    to: string;
    hasArrow?: boolean;
};

const ButtonDark = ({buttonText, extraClasses, to, hasArrow}: ButtonDarkProps) => {
  return (
    <Link to={to}>
        <button className={`${CSS.BUTTON_DARK} ${extraClasses}`}>{buttonText} {hasArrow && <>→</>}</button>
    </Link>
  )
}

export default ButtonDark;
