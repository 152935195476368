import React from 'react';
import collaborationProjectImg from '../../resources/img/demo.png';
import {CSS} from '../../utils/domUtils';
import '../../styles/collaborationPlatform/_demo.scss';
import ButtonGreen from '../common/ButtonGreen';
import { PATHS } from '../../utils/paths';
import { Link } from 'react-router-dom';

const DemoCard: React.FC = () => {
	return (
		<div className={`${CSS.DEMO_CARD} ${CSS.CONTAINER}`}>
			<div className={CSS.WRAPPER}>
				<div className={CSS.IMG_WRAPPER}>
					<img className={CSS.IMG} src={collaborationProjectImg} alt="laptop illustration"/>
					<div className={CSS.TRY_DEMO}>
						<ButtonGreen
							buttonText={'TRY THE DEMO'}
							to={PATHS.DEMO}
						/>
					</div>
				</div>
				<div className={CSS.PLANNING}>
					<div className={CSS.PLANNING_TITLE}>
						Planning a long-term, ongoing collaboration, or looking for
						features or outcomes that aren’t on the list?
					</div>
					<div className={CSS.PLANNING_SUBTITLE}>
						Take a look at our <Link to={PATHS.COMING_SOON}><u>fully customizable solutions</u></Link> instead.
					</div>
				</div>
			</div>
		</div>
	);
};

export default DemoCard;