import {CSS} from '../../utils/domUtils';
import '../../styles/collaborationPlatform/_combiningCard.scss';

const CombiningCard = () => {
    return (
		<div className={`${CSS.CONTAINER} ${CSS.COMBINING_CARD}`}>
			<div className={CSS.LEFT_SUBTITLE}>
				Combining the best and most up-to-date Privacy Enhancing
				Technologies (PETs) with an easy-to-use interface,
			</div>
			<div className={CSS.LEFT_MIDDLE_TEXT}>
				the xtendr Collaboration Platform facilitates <u>collaboration projects that bring together data</u> across 
                teams, departments, organizations, industries, and borders.
			</div>
			<div className={CSS.RIGHT_SUBTITLE}>
				Perfect for fixed-term collaboration projects, <b>our platform features a
				customizable security setup and a comprehensive array of pre-set queries</b> that can be run
				on pooled data without needing any programming knowledge - although custom SQL queries are accommodated as well.
			</div>
		</div>
    );
}

export default CombiningCard;
