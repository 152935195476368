import React, {useState} from 'react';
import Header from '../common/Header';

import '../../styles/_comingSoon.scss';
import {CSS} from '../../utils/domUtils';

const ComingSoonRoute = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={`${CSS.COMING_SOON_ROUTE} ${CSS.BACKGROUND}`}>
        <div className={CSS.WRAPPER}>
            <div className={CSS.CONTAINER}>
                <Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
                <div className={CSS.COMING_SOON}>
                    <div className={CSS.CARD_TITLE}>Coming soon!</div>
                    <div className={CSS.TEXT}>
                        <div className={CSS.MAIN_TEXT}>
                            We’re working on a new version of our website
                        </div>
                        <div className={CSS.CARD_SUBTITLE}>
                            Later this year, you’ll be able to read all about xtendr‘s secure data sharing and collaboration solutions.<br/><br/>
                            Until then, you can contact us with any questions at <a href="mailto:hello@xtendr.io">hello@xtendr.io</a> - or keep up to date with our news on LinkedIn.
                            <a className={CSS.LINKEDIN_BTN} href={`https://www.linkedin.com/company/xtendr`} target="_blank" rel="noreferrer">Linkedin →</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ComingSoonRoute;
