import React from 'react';
import {CSS} from '../../utils/domUtils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NewsLetter = () => {
  return (
    <div className={CSS.NEWSLETTER}>
        <div className={CSS.CARD_TITLE}>
            Get our updates
        </div>
        <div className={CSS.CARD_SUBTITLE}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit maecenas hendrerit ex et consequat.
        </div>
        <div className={`${CSS.EMAIL_INPUT} ${CSS.FLEX_ROW}`}>
            <input type={'text'} placeholder={'YOUR EMAIL'}/>
            <button>
              <div className={CSS.DISPLAY_BIGSCREEN}>Subscribe</div>
              <div className={CSS.DISPLAY_SMALLSCREEN}><ArrowForwardIcon/></div>
            </button>
        </div>
    </div>
  )
}

export default NewsLetter