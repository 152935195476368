import {CSS} from '../../utils/domUtils';
import LogoScroller from './LogoScroller';

const Partners = () => (
  <>
    <div className={CSS.CONTAINER}>
      <div className={CSS.PARTNERS_TITLE}>Partnered with</div>
    </div>
    <LogoScroller />
  </>
);

export default Partners;
