import React from 'react'
import {CSS} from '../../utils/domUtils';
import illustration from '../../resources/img/secureData.svg';
import privacyCardsHorizontal from '../../resources/img/privacyCardsHorizontal.svg';
import privacyCardsVertical from '../../resources/img/privacyCardsVertical.svg';
import '../../styles/homePage/_secureDataCard.scss';
import { Link } from 'react-router-dom';
import { PATHS } from '../../utils/paths';

const SecureDataCard = () => (
  <div className={CSS.SECURE_DATA_CARD}>
    <div className={CSS.CONTAINER}>
      <div className={`${CSS.FLEX_ROW} ${CSS.SECURE_DATA_FIRST_ROW}`}>
        <img className={CSS.SECURE_DATA_IMG} src={illustration} alt="secure data" />
        <div>
          <div className={CSS.CARD_TITLE}>Secure data collaboration</div>
          <div className={CSS.CARD_SUBTITLE}>
            xtendr facilitates secure data sharing between teams, departments and organizations, across borders and industries.<br/><br/>
            <b>Whether you are looking for a fully secure way to share data</b> with an external service provider, or want to find new patterns,
            answers and insights in a broader and more diverse range of data sources, <b>we can help.</b>
          </div>
        </div>
      </div>
      <img className={CSS.SECURE_DATA_IMG_MOBILE} src={illustration} alt="secure data" />
      <div className={CSS.PRIVACY_TEXT}>
        At every stage, xtendr uses a unique combination of <u>Privacy Enhancing Technologies </u> 
        to ensure that data is fully protected, and sensitive information is never visible
      </div>
      <div className={CSS.PRIVACY_SUBTEXT}>
      - meaning that collaboration is compliant with data protection laws and regulations, whatever industry you are in.
      </div>
      <Link to={PATHS.COMING_SOON}><img className={CSS.PRIVACY_CARD_HORIZONTAL} src={privacyCardsHorizontal} alt="secure data" /></Link>
      <img className={CSS.PRIVACY_CARD_VERTICAL} src={privacyCardsVertical} alt="secure data" />
    </div>
  </div>
);

export default SecureDataCard;
