import React from 'react';
import ListItemWithArrow from '../common/ListItemWithArrow';
import {CSS} from '../../utils/domUtils';
import '../../styles/collaborationPlatform/_features.scss';

const Features: React.FC = () => {
	return (
		<div className={`${CSS.FEATURES_CARD} ${CSS.BACKGROUND}`}>
			<div className={CSS.CONTAINER}>
				<div className={CSS.CARD_TITLE}>Features</div>
				<div className={CSS.LIST}>
					<ListItemWithArrow text={'Easy-to-use interface'}/>
					<ListItemWithArrow text={'Adjustable user permissions'}/>
					<ListItemWithArrow text={'Activity logs'}/>
					<ListItemWithArrow text={'Change requests'}/>
					<ListItemWithArrow text={'Import data manually or via API'} optionalClassName={CSS.LAST}/>
				</div>
			</div>
		</div>
	);
};

export default Features;
