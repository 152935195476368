import React from 'react';
import fullLaptop from '../../resources/img/fullLaptop.png';

import '../../styles/demo/_rest.scss';
import {CSS} from '../../utils/domUtils';
import DemoButton from '../common/DemoButton';

const Rest: React.FC = () => (
    <div className={CSS.LIGHT_BACKGROUND}>
        <div className={CSS.CONTAINER}>
            <div className={CSS.IMG_WRAPPER}>
                <img className={CSS.IMG} src={fullLaptop} alt="laptop illustration"/>
            </div>
            <div className={CSS.COMMITED}>
                Take a hands-on look at the <u>xtendr Collaboration Platform</u> with our free demo. Explore the security setup,
                queries and results of two test projects, and learn more about how the platform could work for you.
            </div>
            <div className={CSS.FLEX_CENTER}>
                <DemoButton buttonText={'TRY IT NOW'} extraClasses={CSS.TRY_IT_NOW}/>
            </div>
        </div>
    </div>
);

export default Rest;