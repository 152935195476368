import React from 'react';
import {CSS} from '../../utils/domUtils';
import ListItemWithArrow from '../common/ListItemWithArrow';
import '../../styles/homePage/_discoverCard.scss';

const DiscoverCard: React.FC = () => (
  <div className={`${CSS.DISCOVER_CARD} ${CSS.BACKGROUND} ${CSS.DARK_CARD}`}>
    <div className={CSS.CONTAINER}>
      <div className={CSS.CARD_TITLE}>Discover patterns, enhance insights and improve outcomes</div>
      <div className={CSS.CARD_SUBTITLE}>Gain deeper business insights and achieve real-world results including:</div>
      <div className={CSS.DISCOVER_CARD_LIST}>
        <ListItemWithArrow text={'More accurate and comprehensive research outcomes'}/>
        <ListItemWithArrow text={'Detection of new trends & validation of existing patterns'}/>
        <ListItemWithArrow text={'In-depth mapping of behaviours and processes'}/>
        <ListItemWithArrow text={'Enhanced audience segmentation and insights'}/>
        <ListItemWithArrow text={'Improved Machine Learning outcomes'} optionalClassName={CSS.LAST}/>
      </div>
    </div>
  </div>
);

export default DiscoverCard;
