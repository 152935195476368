import Footer from './components/footer/Footer';
import RenderRoutes from './components/routes/RenderRoutes';
import ScrollToTop from './components/common/ScrollToTop';

const App = () => (
  <>
    <ScrollToTop />
    <RenderRoutes/>
    <Footer/>
  </>
);

export default App;
