import React from 'react';
import {CSS} from '../../utils/domUtils';

interface ListItemWithArrowProps {
	text: string;
	optionalClassName?: string;
}

const ListItemWithArrow: React.FC<ListItemWithArrowProps> = ({text, optionalClassName}) => {
	return (
		<div className={`${CSS.LIST_ITEM_WITH_ARROW} ${optionalClassName || ''}`}>
			<div>
				→
			</div>
			<div>{text}</div>
		</div>
	);
};

export default ListItemWithArrow;