import React from 'react';
import {CSS} from '../../utils/domUtils';
import { Link } from 'react-router-dom';
import { PATHS } from '../../utils/paths';

const Links = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className={`${CSS.FLEX_ROW} ${CSS.LINKS} ${CSS.DISPLAY_BIGSCREEN}`}>
                <div className={`${CSS.FLEX_COLUMN}`}>
                    <Link to={PATHS.COLLABORATION_PLATFORM} onClick={scrollToTop}>Collaboration Platform</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Custom Solutions</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Privacy Enhancing Technologies</Link>
                </div>
                <div className={`${CSS.FLEX_COLUMN}`}>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>How it Works</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Resources</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>FAQ</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>About Us</Link>
                </div>
                <div className={`${CSS.FLEX_COLUMN}`}>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Careers </Link>
                    <Link to={PATHS.CONTACT} onClick={scrollToTop}>Contact</Link>
                    <a className={CSS.LINKEDIN_BIGSCREEN} href={`https://www.linkedin.com/company/xtendr`} target="_blank" rel="noreferrer">Linkedin →</a>
                </div>
            </div>
            <div className={`${CSS.LINKS_SMALLSCREEN}`}>
                <div className={`${CSS.LINK_COLUMN}`}>
                    <Link to={PATHS.COLLABORATION_PLATFORM} onClick={scrollToTop}>Collaboration Platform</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Custom Solutions</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Privacy Enhancing Technologies</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>How it Works</Link>
                </div>
                <div className={`${CSS.LINK_COLUMN}`}>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Resources</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>FAQ</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>About Us</Link>
                    <Link to={PATHS.COMING_SOON} onClick={scrollToTop}>Careers </Link>
                    <Link to={PATHS.CONTACT}>Contact</Link>
                </div>
            </div>
            <a className={CSS.LINKEDIN_SMALLSCREEN} href={`https://www.linkedin.com/company/xtendr`} target="_blank" rel="noreferrer">Linkedin →</a>
        </>
    )
}

export default Links