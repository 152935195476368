import React, {useState} from 'react';
import Header from '../common/Header';
import DemoButton from '../common/DemoButton';

import {CSS} from '../../utils/domUtils';
import '../../styles/demo/_titleCard.scss';

const TitleCard: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<div>
			<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
				<div className={CSS.CONTAINER}>
					<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
					<div className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
						Try our platform, completely free
					</div>
					<div className={CSS.FLEX_CENTER}>
						<DemoButton buttonText={'TRY THE DEMO'}/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TitleCard;