import { Navigate, Route, Routes } from 'react-router-dom';
import HomepageRoute from '../homepage/HomepageRoute';
import { PATHS } from '../../utils/paths';
import ComingSoonRoute from '../comingSoon/ComingSoonRoute';
import ContactRoute from '../contact/ContactRoute';
import CollaborationPlatformRoute from '../collaborationPlatform/CollaborationPlatformRoute';
import DemoRoute from '../demo/DemoRoute';
import '../../styles/_header.scss';

const RenderRoutes = () => (
    <Routes>
        <Route path={PATHS.HOME} element={<HomepageRoute/>} />
        <Route path={PATHS.COMING_SOON} element={<ComingSoonRoute/>} />
        <Route path={PATHS.CONTACT} element={<ContactRoute/>} />
        <Route path={PATHS.COLLABORATION_PLATFORM} element={<CollaborationPlatformRoute/>} />
        <Route path={PATHS.DEMO} element={<DemoRoute/>} />
        <Route path={'*'} element={<Navigate to={PATHS.HOME}/>}/>
    </Routes>
)

export default RenderRoutes;
