import {CSS} from '../../utils/domUtils';
import '../../styles/_logoScroller.scss';
import logos from '../../resources/img/partnerLogos/logos.png';

const LogoScroller = () => {
  return (
    <div className={CSS.LOGO_SCROLLER}>
        <div key={1} className={CSS.LOGO}>
            <img src={logos} alt={`Logo 1`} />
        </div>
        <div key={2} className={CSS.LOGO}>
            <img src={logos} alt={`Logo 1`} />
        </div>
        <div key={3} className={CSS.LOGO}>
            <img src={logos} alt={`Logo 1`} />
        </div>
        <div key={4} className={CSS.LOGO}>
            <img src={logos} alt={`Logo 1`} />
        </div>
    </div>
  );
};

export default LogoScroller;