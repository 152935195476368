import React from 'react';
import {CSS} from '../../utils/domUtils';
import '../../styles/_footer.scss';
import NewsLetter from './NewsLetter';
import Links from './Links';
import logo from '../../resources/img/footerLogo.svg';

const Footer = () => {
  return (
    <div className={`${CSS.FOOTER} ${CSS.BACKGROUND}`}>
      <div className={`${CSS.CONTAINER} ${CSS.FLEX_COLUMN}`}>
        <div className={`${CSS.FLEX_ROW} ${CSS.FOOTER_INFO}`}>
            <NewsLetter/>
            <Links/>
        </div>
        <img src={logo} className={CSS.FOOTER_LOGO} alt={'logo'} />
        <div className={CSS.FOOTER_LEGAL}>©2024 legal</div>
      </div>
    </div>
  )
}

export default Footer;
