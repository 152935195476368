import React, { ReactElement, useRef } from 'react';
import {CSS} from '../../utils/domUtils';
import {ReactComponent as ArrowDown} from '../../resources/img/arrowDown.svg';
import {ReactComponent as ArrowUp} from '../../resources/img/arrowUp.svg';
import '../../styles/collaborationPlatform/_howItWorks.scss';

type AccordionItemProps = {
    nr: number;
    titleText: string;
    panelElement: ReactElement;
	setActiveBullet: (nr: number) => void;
	activeBullet: number;
	img: string
}

const AccordionItem = ({nr, titleText, panelElement, setActiveBullet, activeBullet, img}: AccordionItemProps) => {
	const isPanelOpen = activeBullet === nr;
	const panelRef = useRef<HTMLDivElement>(null);
	const handlePanelClick = () => {
		setActiveBullet(activeBullet !== nr ? nr : 0)
	};

	return (
		<div className={`${CSS.ACCORDION_WRAPPER} ${isPanelOpen && CSS.ACTIVE}`}>
			<div className={CSS.FLEX_ROW} onClick={handlePanelClick}>
				<button className={`${CSS.ACCORDION} ${isPanelOpen && CSS.ACTIVE}`}>{nr}&nbsp;&nbsp;&nbsp;{titleText}</button>
				{isPanelOpen ? <ArrowUp className={CSS.ARROW_UP}/> : <ArrowDown className={CSS.ARROW_DOWN}/> }
			</div>
			<div className={`${CSS.PANEL} ${isPanelOpen && CSS.ACTIVE}`} ref={panelRef} style={{ height: isPanelOpen ? panelRef.current?.scrollHeight : 0 }}>
				{panelElement}
				<div>
					<img
						className={CSS.HOW_IT_WORKS_IMG_SMALL}
						src={img}
						alt="How it works"
					/>
				</div>
			</div>
		</div>
	)
};

export default AccordionItem;
