import React, {useState} from 'react';
import {CSS} from '../../utils/domUtils';
import ButtonDark from '../common/ButtonDark';
import { PATHS } from '../../utils/paths';
import Header from '../common/Header';

import '../../styles/homePage/_titleCard.scss';

const TitleCard: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      	<div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
        	<div className={CSS.CONTAINER}>
                <Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
				<div className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
					Connecting data, protecting privacy
					<ButtonDark buttonText={'Our Platform'} to={PATHS.COLLABORATION_PLATFORM} extraClasses={`${CSS.DISPLAY_BIGSCREEN} ${CSS.OUR_PLATFORM_BUTTON}`} hasArrow={true}/>
				</div>
				<div className={CSS.CARD_SUBTITLE}>
					Valuable insights can often only be found by sharing data - but accessing external
					information without compromising privacy can be challenging. <b>xtendr changes that.</b>
				</div>
				<ButtonDark
					buttonText={'Our Platform'}
					extraClasses={`${CSS.DISPLAY_SMALLSCREEN} ${CSS.OUR_PLATFORM_BUTTON}`}
					to={PATHS.COLLABORATION_PLATFORM}
					hasArrow={true}
				/>
          	</div>
      	</div>
    </>
  )
};

export default TitleCard;
