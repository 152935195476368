import React, {useState} from 'react';
import collaborationProjectImg from '../../resources/img/collaborationProjectImg.png';
import {CSS} from '../../utils/domUtils';
import ButtonDark from '../common/ButtonDark';
import ButtonGreen from '../common/ButtonGreen';
import { PATHS } from '../../utils/paths';
import Header from '../common/Header';
import '../../styles/collaborationPlatform/_titleCard.scss';

const TitleCard: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={CSS.COLLABORATION_PLATFORM}>
        <div className={`${CSS.TITLE_CARD} ${CSS.BACKGROUND}`}>
            <div className={CSS.CONTAINER}>
				<Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
                <div className={CSS.COLLAB_FLEX_ROW}>
                    <div className={CSS.TITLE_WRAPPER}>
                        <div className={`${CSS.TITLE_CARD_TITLE} ${CSS.CARD_TITLE}`}>
                            The xtendr Collaboration Platform
                        </div>
                        <div className={`${CSS.IMG_WRAPPER} ${CSS.DISPLAY_SMALLSCREEN}`}><img src={collaborationProjectImg} alt="laptop illustration" /></div>
                        <div className={CSS.CARD_SUBTITLE}>
                            <b>Accessible, adaptable and secure,</b> the xtendr Collaboration Platform is designed for streamlined collaboration and powerful results.
                        </div>
                    </div>
                    <div className={`${CSS.FLEX_COLUMN} ${CSS.DISPLAY_BIGSCREEN}`}>
                        <div className={CSS.IMG_WRAPPER}>
                            <img src={collaborationProjectImg} alt="collaboration platform UI example" />
                        </div>
                        <div className={CSS.TRY_DEMO}>
                            <ButtonGreen
                                buttonText={'TRY THE DEMO'}
                                extraClasses={CSS.DISPLAY_BIGSCREEN}
                                to={PATHS.DEMO}
                            />
                        </div>
                    </div>
                    <ButtonDark
                        buttonText={'TRY THE DEMO'}
                        extraClasses={`${CSS.DISPLAY_SMALLSCREEN} ${CSS.OUR_PLATFORM_BUTTON}`}
                        to={PATHS.DEMO}
                    /> 
                </div>
            </div>
        </div>
    </div>
  )
};

export default TitleCard;
