import React from 'react';
import {Link} from 'react-router-dom';
import {CSS} from '../../utils/domUtils';

type ButtonDarkProps = {
	buttonText: string;
  extraClasses?: string;
  to: string;
};

const ButtonGreen = ({buttonText, extraClasses, to}: ButtonDarkProps) => {
  return (
    <Link to={to}>
        <button className={`${CSS.BUTTON_GREEN} ${extraClasses}`}><div>{buttonText}</div></button>
    </Link>
  )
}

export default ButtonGreen;
