import React from 'react';
import {ReactComponent as LogoDark} from '../../resources/img/logoDark.svg';
import {ReactComponent as LogoGreen} from '../../resources/img/logoGreen.svg';
import {ReactComponent as hMenuDarkGreen} from '../../resources/img/hMenu/hMenuDarkGreen.svg';
import {ReactComponent as HMenuLight} from '../../resources/img/hMenu/hMenuLight.svg';
import {PATHS} from '../../utils/paths';
import ButtonDark from './ButtonDark';
import {Link} from 'react-router-dom';
import {CSS} from '../../utils/domUtils';
import SideMenu from './SideMenu';

type HeaderProps = {
    isLight?: boolean;
	isMenuOpen: boolean;
	setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header: React.FC<HeaderProps> = ({ isLight, setIsMenuOpen, isMenuOpen }) => {
    const Logo = isLight ? LogoGreen : LogoDark;
    const MenuButton = isLight ? HMenuLight : hMenuDarkGreen;

	return (
		<div className={`${CSS.HEADER}`}>
            <Link to={PATHS.HOME} onClick={() => setIsMenuOpen(false)}><Logo className={CSS.LOGO_DARK}/></Link>
            <div className={`${CSS.FLEX} ${CSS.GAP}`}>
                <ButtonDark buttonText={'Contact us'} to={PATHS.CONTACT} extraClasses={`${CSS.CONTACT_BUTTON}  ${isLight && CSS.LIGHT}`}/>
                <MenuButton className={CSS.MENU_BUTTON} onClick={() => setIsMenuOpen(!isMenuOpen)}/>
                <div className={`${CSS.SIDE_MENU} ${isMenuOpen && CSS.MENU_OPEN}`}>
                    <SideMenu setIsMenuOpen={setIsMenuOpen} />
                </div>
            </div>
		</div>
	);
};

export default Header;
