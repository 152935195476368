import React, {ReactElement} from 'react';
import {CSS} from '../../utils/domUtils';

type OutcomesCardProps = {
	img: string;
	titleText: string;
	listElement: ReactElement;
};

const OutcomesCard: React.FC<OutcomesCardProps> = ({img, titleText, listElement}: OutcomesCardProps) => {
	return (
		<div className={CSS.OUTCOMES_CARD}>
			<div className={CSS.CARD_FLEX}>
				<img
					className={CSS.OUTOMES_IMG}
					src={img}
					alt="Outcomes illustration"
				/>
				<div>
					<div className={CSS.OUTCOMES_CARD_TITLE}>{titleText}</div>
					<div className={CSS.LIST}>{listElement}</div>
				</div>
			</div>
		</div>
	);
};

export default OutcomesCard;