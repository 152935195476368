import React from 'react';
import TitleCard from './TitleCard';
import {CSS} from '../../utils/domUtils';
import CombiningCard from './CombiningCard';
import HowItWorks from './HowItWorks';
import HowWeProtect from '../common/HowWeProtect';
import Features from './Features';
import Outcomes from './Outcomes';
import '../../styles/collaborationPlatform/_common.scss';
import DemoCard from './DemoCard';

const CollaborationPlatformRoute: React.FC = () => (
    <>
        <TitleCard/>
        <div className={CSS.LIGHT_BACKGROUND}>
        <CombiningCard/>
        <HowItWorks/>
        <HowWeProtect/>
        <Features/>
        <Outcomes/>
        <DemoCard/>
        </div>
    </>
);

export default CollaborationPlatformRoute;
