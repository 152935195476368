import { PATHS } from '../../utils/paths';
import React from 'react';
import {CSS} from '../../utils/domUtils';
import {ReactComponent as HMenu} from '../../resources/img/hMenu/hMenuOpen.svg';
import {ReactComponent as LogoGreen} from '../../resources/img/logoGreen.svg';
import {ReactComponent as Solutions2} from '../../resources/img/sideMenu/solutions2.svg';
import {ReactComponent as Solutions3} from '../../resources/img/sideMenu/solutions3.svg';
import {ReactComponent as SolutionsSmall2} from '../../resources/img/sideMenu/solutionsSmall2.svg';
import {ReactComponent as SolutionsSmall3} from '../../resources/img/sideMenu/solutionsSmall3.svg';
import ButtonGreen from './ButtonGreen';
import IllustrationCard from './IllustrationCard';
import { Link } from 'react-router-dom';

type SideMenuProps = {
    setIsMenuOpen: (isOpen: boolean) => void;
}

type SolutionsIllustrationsProps = {
    isBig: boolean;
}

type BigMenuItemProps = {
    text: string;
    itemNr: number;
}

const SideMenu = ({setIsMenuOpen}: SideMenuProps) => {
    const [activeItem, setActiveItem] = React.useState<number | null>(0);

    const closeMenu = () => {
        setIsMenuOpen(false)
        setActiveItem(0)
    };

    const SolutionsIllustrations = ({isBig}: SolutionsIllustrationsProps) => (
        <>
            {/* <IllustrationCard illustration={isBig ? Solutions1 : SolutionsSmall1} redirectLink={''}/> */}
            <IllustrationCard illustration={isBig ? Solutions2 : SolutionsSmall2} redirectLink={PATHS.COLLABORATION_PLATFORM} setIsMenuOpen={setIsMenuOpen}/>
            <IllustrationCard illustration={isBig ? Solutions3 : SolutionsSmall3} redirectLink={PATHS.DEMO} setIsMenuOpen={setIsMenuOpen}/>
        </>
    );

    const BigMenuItem = ({text, itemNr}: BigMenuItemProps) => (
        <div className={`${CSS.MENU_ITEM} ${activeItem === itemNr && CSS.HOVER}`} onMouseOver={() => setActiveItem(itemNr)}>
            {text}
            <div className={CSS.ARROW_ICON} > →</div>
        </div>
    );

    return (
        <div className={`${CSS.CONTAINER} ${CSS.FLEX_COLUMN}`}>
            <div className={`${CSS.FLEX_ROW} ${CSS.HEADER}`}>
                <LogoGreen className={CSS.MENU_BUTTON} onClick={() => setIsMenuOpen(false)}/>
                <div className={`${CSS.FLEX} ${CSS.GAP}`}>
                    <ButtonGreen buttonText={'Contact us'} to={PATHS.CONTACT} extraClasses={CSS.CONTACT_BUTTON}/>
                    <HMenu className={CSS.MENU_BUTTON} onClick={closeMenu}/>
                </div>
                
            </div>
            <div className={`${CSS.FLEX_ROW} ${CSS.MENU_WRAPPER} ${CSS.DISPLAY_BIGSCREEN}`} onMouseLeave={() => setActiveItem(0)}>
                <div className={`${CSS.MENU} ${CSS.FLEX_COLUMN}`}>
                    <BigMenuItem text={'Solutions'} itemNr={1}/>
                    {/* <BigMenuItem text={'Technology'} itemNr={2}/>
                    <BigMenuItem text={'Use-cases'} itemNr={3}/>
                    <BigMenuItem text={'Resources'} itemNr={4}/> */}
                </div>
                {activeItem === 1 &&
                    <div className={CSS.ILLUSTRATIONS_WRAPPER}>
                        <SolutionsIllustrations isBig={true}/>
                    </div>
                }
            </div>
            <div className={`${CSS.MENU_WRAPPER} ${CSS.DISPLAY_MIDDLESCREEN}`} onMouseLeave={() => setActiveItem(0)}>
                <div className={`${CSS.MENU} ${CSS.FLEX_COLUMN}`}>
                    <div className={`${CSS.MENU_ITEM} ${activeItem === 1 && CSS.HOVER}`} onMouseOver={() => setActiveItem(1)}>
                        Solutions 
                        <div className={CSS.ARROW_ICON} > →</div>
                    </div>
                </div>
                {activeItem === 1 &&
                    <div className={CSS.ILLUSTRATIONS_WRAPPER}>
                        <div className={CSS.ILLUSTRATION_INNER_WRAPPER}>
                            <SolutionsIllustrations isBig={true}/>
                        </div>
                    </div>
                }
            </div>
            {activeItem === 0 &&
                <div className={`${CSS.MENU_WRAPPER} ${CSS.DISPLAY_SMALLSCREEN}`}>
                    <div className={CSS.MENU_ITEM} onClick={() => setActiveItem(1)}>Solutions →</div>
                    {/* <div className={CSS.MENU_ITEM} onClick={() => setActiveItem(2)}>Technology</div> */}
                    {/* <div className={CSS.MENU_ITEM} onClick={() => setActiveItem(3)}>Use-cases</div>
                    <div className={CSS.MENU_ITEM} onClick={() => setActiveItem(4)}>Resources</div> */}
                    <Link to={PATHS.CONTACT} className={CSS.CONTACT_LINK}>Contact us ↗</Link>
                </div>
            }
            {activeItem === 1 &&
                <div className={`${CSS.SUBMENU_WRAPPER} ${CSS.DISPLAY_SMALLSCREEN}`}>
                    <div className={CSS.MENU_ITEM_ACTIVE} onClick={() => setActiveItem(0)}>← Solutions</div>
                    <div className={CSS.ILLUSTRATIONS_WRAPPER}>
                        <SolutionsIllustrations isBig={false}/>
                    </div>
                </div>
            }
            <div className={CSS.HEADER_FOOTER}>
                <a className={CSS.LINKEDIN_BIGSCREEN} href={`https://www.linkedin.com/company/xtendr`} target="_blank" rel="noreferrer">Linkedin →</a>
            </div>
        </div>
    )
}

export default SideMenu