import React from 'react';
import { PATHS } from '../../utils/paths';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import {CSS} from '../../utils/domUtils';

const HowWeProtect: React.FC = () => {
	return (
		<div className={`${CSS.PROTECT_DATA} ${CSS.WRAPPER}`}>
			<div className={CSS.BACKGROUND}>
				<div className={CSS.CONTAINER}>
					<Link to={PATHS.COMING_SOON}>
						How we protect data
						<ArrowForwardIcon
							style={{ width: '50px', height: '48px', marginBottom: '-10px' }}
							sx={{ stroke: "#00C495", strokeWidth: 1 }}
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default HowWeProtect;