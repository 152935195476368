import React, { useState } from 'react';
import {CSS} from '../../utils/domUtils';
import '../../styles/_contact.scss';
import Header from '../common/Header';

const ContactRoute = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className={CSS.CONTACT}>
            <div className={CSS.CONTAINER}>
                <Header isLight={true} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>
                <div className={CSS.CARD_TITLE}>Contact Us</div>
                <div className={CSS.MAIN_TEXT}>
                    If you’ve got a use case in mind, are interested in partnership,
                    or would just like to know more, we’re always happy to hear from you.<br/>
                </div>
                <div className={CSS.SUB_TEXT}>
                    Email us at <a href="mailto:hello@xtendr.io">hello@xtendr.io</a> and someone from the team will get back to you!
                </div>
            </div>
      </div>
        
    )
};

export default ContactRoute